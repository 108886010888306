import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/SEO'
import FeatureIcons from '../components/FeatureIcons/FeatureIcons'
import Testimonials from '../components/Testimonials/Testimonials'
import Hero from '../components/Hero/Hero'
import bgImage from '../images/curves.svg'

// import '../styles/pages/index.scss'

import Layout from '../components/Layout'

class IndexPage extends React.Component {
  render() {
    console.log(this.props.data)

    return (
      <Layout slug="home">
        <SEO title="Home | Web and Mobile App Design" />
        <div className="page-wrapper home">
          <Hero
            bgImage={bgImage}
            imageAlt="DH Designs - Website and Mobile app developer - intro background"
          />
          <FeatureIcons />
          <Testimonials
            testimonials={this.props.data.allWordpressWpTestimonials.edges}
          />

          <div className="section is-medium">
            <div className="projects-wrapper container max-w-54rem has-text-centered">
              <h3 className="subtitle">Take a look at my work</h3>
              <p className="lead is-size-5 is-size-6-mobile content">
                To see my latest & greatest client projects, please visit the
                {' '}
                <Link to="/projects">Projects</Link>
                {' '}
page.
              </p>
              <p className="lead is-size-5 is-size-6-mobile">
                As I offer a wide range of both online & offline services, I can
                create a bespoke product for your business that matches &
                exceeds all expectations.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          id
          categories {
            slug
          }
        }
      }
    }
    allWordpressWpTestimonials(sort: { fields: date, order: DESC }, limit: 9) {
      edges {
        node {
          title
          content
          acf {
            short_version
            company_name
          }
          featured_media {
            localFile {
              childImageSharp {
                fixed(width: 150, height: 150, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
