import React from 'react'
import Slider from 'react-slick'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { v4 } from 'uuid'
import { chunk } from 'lodash'
import './Testimonials.scss'

const settings = {
  arrows: true,
  infinite: true,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
}

class Testimonials extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      render: false,
    }
  }

  componentDidMount() {
    this.setState({
      render: true,
    })
  }

  render() {
    const { testimonials } = this.props
    const { render } = this.state

    if (render) {
      return (
        <div
          id="testimonials"
          className="testimonials-wrapper container has-text-centered"
        >
          <h3 className="subtitle">Testimonials</h3>
          <Slider {...settings}>
            {testimonials.map(t => (
              <div className="testimonial" key={v4()}>
                <Img
                  fixed={t.node.featured_media.localFile.childImageSharp.fixed}
                  alt={`${t.node.acf.company_name} - testimonial logo`}
                />
                <div
                  className="testimonial-content content"
                  dangerouslySetInnerHTML={{ __html: t.node.acf.short_version }}
                />
                <div
                  className="testimonial-name"
                  dangerouslySetInnerHTML={{ __html: t.node.title }}
                />
              </div>
            ))}
          </Slider>
        </div>
      )
    }
    return null
  }
}

export default Testimonials
